// LOCAL Environment
/*
export const properties = {
  host: "https://bc056e6f-25bb-47e1-81b5-0a2b0aa8f9d2.mock.pstmn.io/",
  apiKeyHeader: "PMAK-615abf38914ed700354a72ab-0a2869200705b1e2f62bfe75af888e3b6e",
  env: "LOCAL",
  loginURL:
    "https://dev-next-motivators.auth.eu-west-1.amazoncognito.com/login?client_id=a1ksijope25121ecee2nat2vk&response_type=token&scope=email+openid+profile&redirect_uri=http://localhost:3000/admin",
};
*/

// DEV Environment
export const properties = {
  host: "https://api.dev.motivators.adpi.bbvanext.tech/",
  env: "DEV",
  loginURL:
    "https://dev-next-motivators.auth.eu-west-1.amazoncognito.com/login?client_id=3jgfvvtpeb4g21r8avliovrnv0&response_type=token&scope=email+openid+profile&redirect_uri=https://admin.dev.motivators.adpi.bbvanext.tech/admin",
};

// INT Environment
/*
export const properties = {
  host: "https://api.int.motivators.adpi.bbvanext.tech/",
  env: "INT",
  loginURL: 
    "https://int-next-motivators.auth.eu-west-1.amazoncognito.com/login?client_id=2vvemuq01m3ev6a1pb6d46hs07&response_type=token&scope=email+openid+profile&redirect_uri=https://admin.int.motivators.adpi.bbvanext.tech/admin",
};
*/

// PRO Environment
/*
export const properties = {
  host: "https://api-motivators.bbvanexttechnologies.com/",
  env: "PRO",
  loginURL: 
    "https://pro-next-motivators.auth.eu-west-1.amazoncognito.com/login?client_id=3ot8r4hnc4un5t3g7ub84alh14&response_type=token&scope=email+openid+profile&redirect_uri=https://admin-motivators.bbvanexttechnologies.com/admin",
};
*/
